
  import { Component, Vue } from 'vue-property-decorator';
  import Drawer from '@/components/Drawer.vue';
  import { $config, $content } from "@/services";

declare var MediaRecorder: any;

  @Component({
    components: {
      Drawer
    },
  })
  export default class Home extends Vue {


    image = new Image();
    currentPhoto: string | null = null;
    someWork = false;
    pi = 50;

    $refs!: {
      intro: HTMLInputElement;
      canvas: any;
    };

    get config() {
      return $config.config;
    }

    get content() {
      return $content.data;
    }

    get width() {
      return window.innerWidth;
    }

    get height() {
      return window.innerHeight;
    }



    get canvas() {
      return this.$refs.canvas;
    }

    get ctx() {
      return this.canvas.getContext('2d') as CanvasRenderingContext2D;
    }


    mounted() {
      const url = $content.data.intro_image.path;
      this.image.src = url;
      this.image.onload = () => {
        this.drawImage();
        //this.buildVideo();        
      }
      //this.$refs.intro.style.backgroundImage = "url("+url+")";

      this.someWork = this.content.ourwork.some((work: any) => work.active)
      this.ctx.fillStyle = "black";
      // if(!this.config.show_cursor){
      //   document.body.parentElement!.style.cursor = "none";
      // }
      //window.addEventListener('resize', this.drawImage);
      // setInterval(()=>{
      //   this.drawImage(Date.now());
      // },1000)


    }

    buildVideo() {
      let chunks:any = [];
      let stream = this.canvas.captureStream(30); // fps
      // Create media recorder from canvas stream
      
      let recorder = new MediaRecorder(stream, { mimeType: "video/webm; codecs=vp9" });
      recorder.ondataavailable = (event:any) => chunks.push(event.data);
      recorder.onstop = () => {
        let blob = new Blob(chunks, { type: "video/webm" });
        const url = URL.createObjectURL(blob);

        const a:any = document.createElement('a');
        a.style = "display: none;";
        a.href = url;
        a.download = "video.webm";
        document.body.appendChild(a);
        a.click();
      }
      recorder.start();
      setTimeout(() => recorder.stop(), 17000);

    }



    drawImage() {
      const lum = Math.min(this.pi * 0.3, 70) | 0;
      const light = (Math.cos(this.pi * 0.1) + 1) * 10 + 80 | 0;
      const angle = this.pi % 360;
      const saturation = `hsl(${angle},100%,${lum}%)`;
      this.pi += 2;


      //
      // cover image instead of stretch
      // https://stackoverflow.com/a/66560970

      this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
      this.ctx.globalCompositeOperation = "multiply";
      this.ctx.fillStyle = saturation;  // saturation at 100%
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);  // apply the comp filter
      this.ctx.globalCompositeOperation = "source-over";  // restore default comp    
      setTimeout(() => {
        requestAnimationFrame(this.drawImage)
      }, 80)
    }


  }
